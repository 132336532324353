//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CityModel } from '@/modules/city/city-model';
import { mapGetters, mapActions } from 'vuex';
import { CityPermissions } from '@/modules/city/city-permissions';
import { i18n, getLanguageCode } from '@/i18n';

const { fields } = CityModel;
// function i18n(key, args) {
//  // return i18n(code);
//   return this.$t(key, args);
// }
export default {
  name: 'app-city-list-table',

  data() {
    return {
      language: getLanguageCode(),
      selectedID: null,
      dialogVisible: false,
      modalVisible: false,

      Pagination: {
        rowsPerPage: 10
      },
      columns: [
        {
          name: 'name',
          field: 'name',
          label: i18n('entities.city.fields.name'),
          align: 'center',
          // format: val => `${val}`,
          // sortable: true,  
          required: true,
        },
        { 
          name: 'action', 
          field: 'action', 
          label: i18n('tables.admins.action'), 
          align: 'center', 
        },
      ],
    }
  },
  mounted() {
    this.doMountTable(this.$refs.table);
  },
  computed: {
    ...mapGetters({
      rows: 'city/list/rows',   
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      loading: 'city/list/loading',
      pagination: 'city/list/pagination',
      destroyLoading: 'city/destroy/loading',
    }),
    hasPermissionToEdit() {
      return new CityPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new CityPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doMountTable: 'city/list/doMountTable',
      doDestroy: 'city/destroy/doDestroy',
    }),
    i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return CityModel.presenter(row, fieldName);
    },
    onOpenModal(id) {
      this.selectedID = id;
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.selectedID = null;
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.onModalClose();
      } catch (error) {
        // no
      }
    },

    // show (id) {
    //   this.selected_ID = id;
    //   this.$refs.dialog.show()
    // },
    // // following method is REQUIRED
    // // (don't change its name --> "hide")
    // hide () {
    //   this.$refs.dialog.hide()
    // },
    // onDialogHide () {
    //   // required to be emitted
    //   // when QDialog emits "hide" event
    //   this.selected_ID = '';
    //   this.$emit('hide')
    // },
    // onOKClick () {
    //   this.doDestroy(this.selected_ID);
    //   // then hiding dialog
    //   this.hide()
    // },
    // onCancelClick () {
    //   // we just need to hide dialog
    //   this.selected_ID = '';
    //   this.hide()
    // },
  },
};

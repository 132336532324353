//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import CityListToolbar from '@/modules/city/components/city-list-toolbar.vue';
// import CityListFilter from '@/modules/city/components/city-list-filter.vue';
// import CityFilterPage from '@/modules/city/components/city-filter-page.vue';
import CityListTable from '@/modules/city/components/city-list-table.vue';
import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';

// import { FilterSchema } from '@/shared/form/filter-schema';
// import { CityModel } from '@/modules/city/city-model';
// const { fields } = CityModel;

// const filterSchema = new FilterSchema([
//   fields.id,
//   fields.createdAtRange,
//   fields.name
// ]);

export default {
  name: 'app-city-list-page',

  components: {
    // [CityListToolbar.name]: CityListToolbar,
    // [CityListFilter.name]: CityListFilter,
    // [CityFilterPage.name]: CityFilterPage,
    [CityListTable.name]: CityListTable,
  },

  data() {
    return {
      // rules: filterSchema.rules(),
      model: {},
      vDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'city/list/loading',
      filter: 'city/list/filter',
    }),
    // fields() {
    //   return fields;
    // },
  },
 
  async mounted() {
    // this.model = filterSchema.initialValues(
    //   this.filter,
    //   this.$route.query,
    // );
    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'city/list/doReset',
      doFetch: 'city/list/doFetch',
    }),
    i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
    closeDialog(){
      this.vDialog = false;
    },
    openDialog(){
      this.vDialog = true
    },
    async doFilter() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }
  
      // const filter = filterSchema.cast(this.model);
      const filter = this.filter
      return this.doFetch({
        filter,
      });
    },
  },
};

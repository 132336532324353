import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class CityPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.cityRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.cityImport,
    );
    this.cityAutocomplete = permissionChecker.match(
      Permissions.values.cityAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.cityCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.cityEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.cityDestroy,
    );
  }
}
